<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: 'Verdana';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0063BE;
  margin-top: 60px;
}
body {
    background: #E5EFF9;
    color: #0063BE;
    font-size: 100%;
    padding-right: 8.5%;
    padding-left: 8.5%;
}
.deconnexion {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width:4%;
  height:auto;
  cursor:pointer;
  margin-top: 20px;
  margin-right: 20px;
}
p {
    font-size: 14px;
}
input[type=password], input[type=text] {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.line {
  text-decoration: none;
  border-radius: 4px;
}
.lineShadow {
  padding: 15px;
  text-align: left;
  text-decoration: none;
  margin: 14px 20%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

button:hover, input[type=submit]:hover{
    background-color:#003862;
    color: white;
}

button:focus, input[type=submit]:focus{
    background-color: #0A66BB;
    color: white
}

button, input[type=submit]{
    color: #0063BE;
    border: 1px solid #0A66BB;
    background-color: transparent;
    background-repeat: no-repeat;
    border-radius: 5px;
    font-family: 'Verdana';
    font-size: 14px;
    width: 163px;
    height: 30px;
    margin: 3px;
    cursor: pointer;
    font-weight: bold;
}
::-webkit-file-upload-button {
  background-color: #0A66BB;
  color:white;
  padding: 15px 32px;
  font: 'Verdana';
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin: 4px 2px;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: #0A66BB;
}
select {
  border: 1px solid #0A66BB;
  background-color: transparent;
  background-repeat: no-repeat;
  font-family: 'Verdana';
  font-size: 14px;
  color: #0063BE;
  text-align-last:center;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  margin: 3px;
}
select:hover, select:focus{
    background-color: #0A66BB;
    color: white;
}

@media only screen and (max-width: 820px) {
  .lineShadow {
    margin: 0px;
    margin-bottom: 5%;
    padding: 4%;
  }
  body {
    font-size: 100%;
  }
  .deconnexion {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width:10%;
    height:auto;
    cursor:pointer;
    margin-top: 20px;
    margin-right: 20px;
  }
}
</style>
