import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Connexion', component: () => import('../pages/Connexion.vue') },
  { path: '/Menu', name: 'Menu', component: () => import('../pages/Menu.vue') },
  { path: '/CreerCompte', name: 'CreerCompte', component: () => import('../pages/CreerCompte.vue') },
  { path: '/Utilisateur', name: 'Utilisateur', component: () => import('../pages/Utilisateur.vue') },
  { path: '/Fichiers', name: 'Fichiers', component: () => import('../pages/Fichiers.vue') },
  { path: '/ForgotPassword', name: 'ForgotPassword', component: () => import('../pages/ForgotPassword.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router